<template>
  <div class="mt-4">
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>ID: {{ form.id }}</span>
          <h5 class="font-weight-bold mb-1">{{ form.program_name }}</h5>
          <span v-if="form.major" class="form-text mt-0 text-muted"
            ><i class="bi bi-arrow-return-right"></i> {{ form.major }}</span
          >
        </div>
        <form @submit.prevent="addReference">
          <div class="form-group">
            <label for="">Code</label>
            <div class="form-inline">
              <input
                type="text"
                v-model="reference.code"
                class="form-control mr-2"
                required
              />
              s.
              <input
                type="text"
                v-model="reference.series"
                class="form-control ml-2"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="">Title</label>
            <textarea
              v-model="reference.title"
              type="text"
              class="form-control"
              required
            ></textarea>
          </div>

          <div class="form-group">
            <label for="">Reference File</label>
            <input
              id="program-psg-file-input"
              type="file"
              class="form-control-file"
              accept="application/pdf"
              @change="handleFileInput"
              required
            />
          </div>

          <button
            type="submit"
            id="add-reference-btn"
            class="btn btn-primary mt-2"
          >
            Add
          </button>
        </form>
      </div>
    </div>
    <div class="">
      <div class="">
        <ul class="list-group">
          <li class="list-group-item" v-for="ref in form.refs" :key="ref.code">
            <div>
              <a href="#" @click.prevent="openUrl(ref.file)">
                <h5 class="">
                  <i class="bi bi-file-pdf"></i> {{ ref.code }}
                </h5></a
              >

              <p>
                {{ ref.title }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramReferencesTab',
  props: {
    form: {
      required: true,
    },
    areas: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedAreas: [],
      area: new Form({
        area_id: '',
        requirements: '',
        program_id: this.form.id,
      }),
      programAreas: [],
      prefix: 'https://drive.google.com/file/d/',
      reference: {
        code: '',
        series: '',
        title: '',
        file: '',
      },
    };
  },
  methods: {
    handleFileInput(event) {
      this.reference.file = event.target.files[0];
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },

    addReference() {
      let btn = document.getElementById('add-reference-btn');

      btn.disabled = true;
      btn.innerHTML = 'Adding...';

      let fd = new FormData();

      fd.append('file', this.reference.file);
      fd.append('title', this.reference.title);
      fd.append('code', `${this.reference.code} s. ${this.reference.series}`);
      fd.append('id', this.form.id);

      axios
        .post('api/programs/reference', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Add';
          this.$emit('updateProgramTable');
          $('#add-program-modal').modal('hide');
          this.reference.code = this.reference.series = this.reference.file = this.reference.title =
            '';
          document.getElementById('program-psg-file-input').value = '';
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Add';
          toastr.error('Something went wrong.', 'Unable to upload file');
        });
    },
  },
};
</script>
