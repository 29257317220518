<template>
  <div class="mt-4">
    <div class="card">
      <div class="card-body">
        <div class="card-title">
          <span>ID: {{ form.id }}</span>
          <h5 class="font-weight-bold mb-1">{{ form.program_name }}</h5>
          <span v-if="form.major" class="form-text mt-0 text-muted"
            ><i class="bi bi-arrow-return-right"></i> {{ form.major }}</span
          >
        </div>
        <div class="form-group">
          <label for="">Area</label>
          <select
            name=""
            class="form-control col-auto"
            id=""
            v-model="area.area_id"
          >
            <option value="" disabled selected hidden>Choose</option>
            <option v-for="a in areas" :key="a.id" :value="a.id">
              {{ a.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="">CMO Requirements</label>
          <textarea
            v-model="area.requirements"
            name=""
            id=""
            cols="30"
            rows="4"
            class="form-control"
            placeholder="You may list down all specific requirements of the respective area."
          ></textarea>
        </div>
        <div>
          <button
            id="add-program-area-btn"
            class="btn btn-primary"
            @click="addProgramArea"
          >
            Add
          </button>
        </div>
      </div>
    </div>

    <ul class="list-group">
      <li
        class="list-group-item"
        v-for="(area, index) in programAreas"
        :key="area.id"
      >
        <div>
          <div class="d-flex justify-content-between">
            <h6 class="font-weight-bold">{{ index + 1 }} . {{ area.name }}</h6>
            <span class="text-muted">{{ created_at | completeDateTime }}</span>
          </div>

          <p style="white-space:pre-line" class="mt-3">
            {{ area.cmo_requirements }}
          </p>

          <a
            href="#"
            class="text-danger"
            @click.prevent="deleteProgramArea(area)"
            ><i class="bi bi-trash-fill text-danger "></i> Remove</a
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProgramAreasOfEvaluationTab',
  props: {
    form: {
      required: true,
    },
    areas: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedAreas: [],
      area: new Form({
        area_id: '',
        requirements: '',
        program_id: this.form.id,
      }),
      programAreas: [],
    };
  },
  methods: {
    addProgramArea() {
      if (this.area.area_id == '' || this.area.requirements == '') {
        toastr.error('Please fill in required fields.', 'Required fields');
        return;
      }

      let btn = document.getElementById('add-program-area-btn');
      btn.disabled = true;
      btn.innerHTML = 'Adding...';

      axios
        .post('api/programs/areas', this.area)
        .then((res) => {
          this.area.reset();
          this.getProgramAreas();
          btn.disabled = false;
          btn.innerHTML = 'Add';
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Add';
        });
    },
    deleteProgramArea(area) {
      if (
        !confirm(
          'This action cannot be undone. Are you sure you want to proceed?'
        )
      )
        return;

      axios
        .delete('api/programs/areas/' + area.id, area)
        .then((res) => {
          this.getProgramAreas();
        })
        .catch((err) => {
          toastr.error('Unable to delete area.', 'Oops! Something went wrong.');
        });
    },
    getProgramAreas() {
      axios
        .get('api/programs/areas/' + this.form.id)
        .then((res) => {
          this.programAreas = res.data;
        })
        .catch((err) => {
          toastr.error('Unable to fetch areas.', 'Oops! Something went wrong.');
        });
    },
  },
  mounted() {
    this.getProgramAreas();
  },
};
</script>
