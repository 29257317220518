<template>
  <div class="mt-4">
    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <label for="">Program Name</label>
          <input
            disabled
            type="text"
            v-model="form.program_name"
            class="form-control"
            required
          />
        </div>

        <div class="form-group">
          <label for="">Major (Optional)</label>
          <input
            type="text"
            v-model="form.major"
            disabled
            class="form-control"
          />
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="">Discipline Code</label>
              <input
                disabled
                type="text"
                class="form-control"
                v-model="form.discipline_code"
                required
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="">Level Code</label>
              <select
                disabled
                name=""
                class="form-control"
                v-model="form.level_code"
                id=""
                required
              >
                <option value="" selected disabled hidden>Choose</option>
                <option value="50">50</option>
                <option value="40">40</option>
                <option value="80">80</option>
                <option value="90">90</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramDetailsTab',
  props: {
    form: {
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {},
};
</script>
